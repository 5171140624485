const base_url = process.env.REACT_APP_BASE_URL;

export const clientes = `${base_url}/client`;
export const comment = `${base_url}/comment`;
export const persons = `${base_url}/person`;
export const organization = `${base_url}/organization`;
export const party = `${base_url}/party`;
export const contact = `${base_url}/contactMethod`;
export const maestros = `${base_url}/maestros/getDatosMaestros`;
export const servicios = `${base_url}/service`;
export const proveedores = `${base_url}/supplier`;
