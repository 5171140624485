import {
  Box,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DocumentoForm from "./DocumentoForm";
import { isEmpty } from "../../Forms/validator";
import { useEffect } from "react";
import "dayjs/locale/es-us";

const DatosPersonales = ({
  obj,
  handleObj,
  handleDate,
  edit,
  setCont,
  next,
  setNext,
}) => {
  const cont = () =>
    isEmpty([
      obj.personName,
      obj.dateOfBirth,
      obj.personSurname,
      obj.dateOfBirth,
      obj.documentType.description,
      obj.documentNumber,
      obj.gender,
    ]);

  const valid = cont();
  useEffect(() => {
    setCont(valid);
    setNext({ ...next, two: valid });
  }, [obj]);

  const gender = ["Femenino", "Masculino", "Otro"];

  return (
    <Box id="datos-personales-container" width={"100%"} paddingY={2}>
      <Box
        id="input-container"
        component="form"
        onChange={handleObj}
        sx={{
          display: "grid",
          gap: 1,
          gridTemplateColumns: "repeat(1, 1fr)",
          width: 100 / 100,
        }}
      >
        <Box>
          <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
            Nombres <span style={{ color: "#FF8200" }}>*</span>
          </Typography>
          <OutlinedInput
            name="personName"
            value={obj.personName}
            sx={{ height: 27, width: 90 / 100, fontSize: 13 }}
            inputProps={{ maxLength: 100 }}
          />
        </Box>
        <Box>
          <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
            Apellidos <span style={{ color: "#FF8200" }}>*</span>
          </Typography>
          <OutlinedInput
            sx={{ height: 27, width: 90 / 100, fontSize: 13 }}
            id="component-outlined"
            value={obj.personSurname}
            name="personSurname"
            inputProps={{ maxLength: 100 }}
          />
        </Box>

        <Box
          id="date&gender"
          sx={{
            display: "grid",
            gap: 1,
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <Box id="gender">
            <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
              Sexo <span style={{ color: "#FF8200" }}>*</span>
            </Typography>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                size="small"
                sx={{ height: 27, fontSize: 13 }}
                value={obj.gender}
                name="gender"
                inputProps={{ "aria-label": "Without label" }}
                onChange={handleObj}
              >
                {gender.map((e, i) => (
                  <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box id="date" sx={{ width: 150 }}>
            <Typography
              id="date-title"
              sx={{ fontSize: 12, paddingBottom: 0.5 }}
            >
              Fecha de Nacimiento <span style={{ color: "#FF8200" }}>*</span>
            </Typography>
            <LocalizationProvider
              id="localization-provider"
              class="localize"
              adapterLocale="es-us"
              dateAdapter={AdapterDayjs}
            >
              <DesktopDatePicker
                id="date-picker"
                inputProps={{
                  "aria-label": "Without label",
                  sx: {
                    paddingY: 0.4,
                    paddingX: 0.5,
                    fontSize: 13,
                    height: 21,
                  },
                }}
                inputFormat="DD/MM/YYYY"
                value={obj.dateOfBirth}
                onChange={handleDate}
                renderInput={(params) => (
                  <TextField
                    id="date-input"
                    name="dateOfBirth"
                    size="small"
                    key={params + 1}
                    {...params}
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
      <DocumentoForm edit={edit} handleDoc={handleObj} obj={obj} />
    </Box>
  );
};

export default DatosPersonales;
