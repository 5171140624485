import { useState, useEffect } from "react";
import { Box, Typography, Divider, CircularProgress } from "@mui/material";
import BreadcrumBar from "../../components/Breadcrumb/BreadcrumBar";
import ServicioForm from "../../components/Forms/ServicioForm";
import { servicioObj } from "./utils";
import { useParams } from "react-router-dom";
import { getServicioById } from "../../controllers/servicios";

const EditarServicio = () => {
  const [servicio, setServicio] = useState(servicioObj);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const handleServicio = (e) => {
    const { name, value } = e.target;
    setServicio({ ...servicio, [name]: value });
  };

  useEffect(() => {
    const getServicio = async () => {
      const res = await getServicioById(id);
      setServicio(res);
      setLoading(false);
    };
    getServicio();
  }, [id]);
  return (
    <Box
      display="flex"
      alignContent={"center"}
      flexDirection={"column"}
      width={100 / 100}
    >
      <BreadcrumBar breadcrumbs={"Gestión de servicios"} />
      <Box
        height={"auto"}
        width={100 / 100}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 550,
            minHeight: "auto",
            background: "white",
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={1}
          >
            <Typography
              sx={{
                color: "#007749",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "'Roboto', sans-serif",
                paddingX: 2,
              }}
            >
              Modificar Servicio
            </Typography>
            <Typography sx={{ fontSize: 13 }}>
              Los campos <span style={{ color: "#FF8200" }}>*</span> son
              obligatorios
            </Typography>
          </Box>
          <Divider sx={{ color: "#F3F6F9" }} />
          {loading ? (
            <Box
              width={100 / 100}
              height={300}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <ServicioForm
              servicio={servicio}
              handleChange={handleServicio}
              id={id}
              edit
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EditarServicio;
