import moment from "moment";

export const orgOrPerson = [
  { text: "Persona física", value: "PERSONA" },
  { text: "Persona jurídica", value: "ORGANIZACION" },
];

export const clienteState = {
  partyType: "",
  partyDescription: "",
  orgRegisteredName: "",
  orgName: "",
  organizationSector: { id: "", name: "" },
  organizationType: { id: "", name: "" },
  orgCountry: { id: "", name: "" },
  documentType: { id: "", description: "" },
  documentNumber: "",
  personName: "",
  personSurname: "",
  gender: "",
  dateOfBirth: "",
  partyTo: { id: "", name: "" },
  fromDate: moment(),
  comment: "",
  partyFrom: { id: "", name: "" },
  obs: "",
  telefono: "",
  addressLine1: "",
  addressNumber: "",
  addressDpto: "",
  city: "",
  postalCode: "",
  state: "",
  taxConditionType: { id: "", description: "" },
  country: { name: "", id: "" },
};
export const proveedorState = {
  partyType: "",
  partyDescription: "",
  orgRegisteredName: "",
  orgName: "",
  organizationSector: { id: "", name: "" },
  organizationType: { id: "", name: "" },
  orgCountry: { id: "", name: "" },
  documentType: { id: "", description: "" },
  documentNumber: "",
  personName: "",
  personSurname: "",
  gender: "",
  dateOfBirth: "",
  partyTo: { id: "", name: "" },
  fromDate: moment(),
  comment: "",
  partyFrom: { id: "", name: "" },
  obs: "",
  telefono: "",
  addressLine1: "",
  addressNumber: "",
  addressDpto: "",
  city: "",
  postalCode: "",
  state: "",
  country: { name: "", id: "" },
  taxConditionType: { id: "", description: "" },
  businessActivityType: { id: "", description: "" },
  categoryType: { id: "", description: "" },
};
