import { useSelector } from "react-redux";
import { trueFalse } from "./utils";
import {
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

const DatosServicio = ({ servicio, handleChange }) => {
  const { serviceType } = useSelector((s) => s);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginTop: 2,
      }}
    >
      <Box paddingY={1}>
        <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
          Nombre <span style={{ color: "#FF8200" }}>*</span>
        </Typography>
        <OutlinedInput
          placeholder="Nombre"
          name="name"
          fullWidth
          value={servicio.name}
          sx={{ height: 27, fontSize: 13 }}
          onChange={handleChange}
          inputProps={{ maxLength: 150 }}
        />
      </Box>
      <Box paddingY={1}>
        <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
          Descripción <span style={{ color: "#FF8200" }}>*</span>
        </Typography>
        <OutlinedInput
          placeholder="Descripción"
          name="description"
          value={servicio.description}
          onChange={handleChange}
          fullWidth
          multiline
          minRows={1}
          maxRows={3}
          inputProps={{ maxLength: 150 }}
          size="small"
          sx={{ fontSize: 13, minHeight: 27 }}
        />
        <Typography
          sx={{
            fontSize: 12,
            paddingX: 0.5,
            paddingTop: 0.5,
            textAlign: "end",
            color: "#7C878E",
          }}
        >
          {servicio.description.length}/150
        </Typography>
      </Box>
      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
        <Box paddingY={1}>
          <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
            Tipo de Servicio <span style={{ color: "#FF8200" }}>*</span>
          </Typography>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              size="small"
              sx={{ height: 27, fontSize: 13 }}
              value={servicio.serviceType}
              name="serviceType"
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}
              renderValue={() => servicio.serviceType.description}
            >
              {serviceType.map((e, i) => (
                <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                  {e.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {servicio.enabled !== "" && (
          <Box paddingY={1}>
            <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
              Estado
            </Typography>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                size="small"
                sx={{ height: 27, fontSize: 13 }}
                value={servicio.enabled}
                name="enabled"
                onChange={handleChange}
                inputProps={{ "aria-label": "Without label" }}
                renderValue={() =>
                  servicio.enabled ? "Habilitado" : "Deshabilitado"
                }
              >
                {trueFalse.map((e, i) => (
                  <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                    {e ? "Habilitado" : "Deshabilitado"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DatosServicio;
