import logoColor from "../../utils/logo-color.png";
import background from "../../utils/loginbg.png";
import { Paper, Box, Typography, Tooltip } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../utils/theme";
import { AzureLogin } from "./AzureLogin";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneEnabledRoundedIcon from "@mui/icons-material/PhoneEnabledRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
const Login = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        width={100 / 100}
        height="100%"
      >
        <Box
          id="loginBox"
          sx={{
            backgroundImage: `url(${background})`,
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "center",
            backgroundSize: "cover",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Paper
            sx={{
              background: "rgba(250,250,250,0.6)",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              margin: "5rem",
              borderRadius: "20px",
            }}
          >
            <Box sx={{ padding: 2, width: 500 }}>
              <img src={logoColor} alt="logo-color" width={240} />
              <Typography
                sx={{
                  color: "#707070",
                  fontSize: 55,
                  fontWeight: 600,
                  padding: 2,
                  fontFamily: "'Roboto', sans-serif",
                }}
              >
                MDM
              </Typography>
              <Typography sx={{ color: "#707070", fontSize: 23, padding: 2 }}>
                Portal de Ingreso al Master <br />
                Data Management
              </Typography>
            </Box>
            <AzureLogin />
          </Paper>
        </Box>
        <Box sx={{ height: 180 }}>
          <Box
            sx={{
              paddingTop: 4,
              paddingX: 10,
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-around",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                color: "#38485C",
                fontSize: 18,
                fontWeight: 800,
              }}
            >
              CONTACTO
            </Typography>
            <Box sx={{ paddingY: 2, display: "flex", flexDirection: "column" }}>
              <a
                href="https://goo.gl/maps/SaFpb5zCmWy6S2VT7"
                target={"_blank"}
                rel="noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#707070",
                  }}
                >
                  <LocationOnIcon />
                  <Tooltip title="Ver en el mapa" placement="right" arrow>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        padding: 1,
                        color: "#38485C",
                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      Avda. Ramón Castillo int. R. Obligado (C1425DAA) CABA -
                      Argentina
                    </Typography>
                  </Tooltip>
                </Box>
              </a>
              <a href={"tel:541159836000"}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#707070",
                  }}
                >
                  <PhoneEnabledRoundedIcon />
                  <Tooltip title="Realizar llamada" placement="right" arrow>
                    <Typography
                      href="tel541159836000"
                      sx={{
                        fontSize: "12px",
                        padding: 1,
                        color: "#38485C",
                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      T. (54 11) 5983-6000
                    </Typography>
                  </Tooltip>
                </Box>
              </a>
              <a href="mailto:info@grupomurchison.com.ar">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#707070",
                  }}
                >
                  <EmailRoundedIcon />
                  <Tooltip title="Enviar correo" placement="right" arrow>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        padding: 1,
                        color: "#38485C",
                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      info@grupomurchison.com.ar
                    </Typography>
                  </Tooltip>
                </Box>
              </a>
            </Box>
          </Box>
          <Box id="footer" sx={{ background: "#86909C" }}>
            <Typography
              sx={{
                color: "#FFFFFF",
                paddingLeft: 10,
                fontSize: 10,
                paddingY: 1.7,
              }}
            >
              GRUPO MURCHISON © 2023 - Todos los derechos reservados
            </Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
