export const servicioPostDTO = (servicio) => {
  const { name, description, serviceType, user } = servicio;
  return {
    name: name,
    description: description,
    serviceTypeId: serviceType.id,
    createdBy: user,
  };
};

export const servicioPutDTO = (obj) => {
  const { name, description, serviceType, enabled, user } = obj;
  return {
    name: name,
    description: description,
    serviceTypeId: serviceType.id,
    enabled: enabled,
    updatedBy: user,
  };
};
