import {
  Box,
  Typography,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
} from "@mui/material";
import { useSelector } from "react-redux";
import DocumentoForm from "./DocumentoForm";
import { isEmpty } from "../../Forms/validator";
import { useEffect } from "react";
const DatosOrganizacion = ({ obj, handleObj, setCont, next, setNext }) => {
  const { organizationSector, organizationType, countries } = useSelector(
    (s) => s
  );
  const cont = () =>
    isEmpty([
      obj.orgName,
      obj.organizationType.description,
      obj.orgRegisteredName,
      obj.organizationSector.description,
      obj.documentType.description,
      obj.documentNumber,
      obj.orgCountry.name,
    ]);

  const valid = cont();
  useEffect(() => {
    setCont(valid);
    setNext({ ...next, two: valid });
  }, [obj]);

  return (
    obj && (
      <Box id="datos-organizacion-container" width={"100%"} paddingX={0.5}>
        <Box
          id="input-container"
          sx={{
            display: "grid",
            gap: 1,
            gridTemplateColumns: "repeat(1, 1fr)",
            width: 100 / 100,
          }}
        >
          <Box>
            <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
              Nombre <span style={{ color: "#FF8200" }}>*</span>
            </Typography>
            <OutlinedInput
              name="orgName"
              value={obj.orgName}
              sx={{ height: 27, width: 100 / 100, fontSize: 12 }}
              inputProps={{ maxLength: 50 }}
              onChange={handleObj}
            />
          </Box>

          <Box>
            <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
              Razón Social <span style={{ color: "#FF8200" }}>*</span>
            </Typography>
            <OutlinedInput
              sx={{ height: 27, width: 100 / 100, fontSize: 12 }}
              id="component-outlined"
              value={obj.orgRegisteredName}
              name="orgRegisteredName"
              onChange={handleObj}
              inputProps={{ maxLength: 150 }}
            />
          </Box>

          <Box
            id="type&sector"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // gap: 1,
              // gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            <Box id="tipo">
              <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
                Tipo de organización <span style={{ color: "#FF8200" }}>*</span>
              </Typography>
              <FormControl sx={{ minWidth: 220 }}>
                <Select
                  size="small"
                  sx={{ height: 27, fontSize: 12 }}
                  value={obj.organizationType}
                  name="organizationType"
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleObj}
                  renderValue={() => obj.organizationType.description}
                >
                  {organizationType.map((e, i) => (
                    <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                      {e.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box id="sector">
              <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
                Sector <span style={{ color: "#FF8200" }}>*</span>
              </Typography>
              <FormControl sx={{ minWidth: 200 }}>
                <Select
                  size="small"
                  sx={{ height: 27, fontSize: 12 }}
                  value={obj.organizationSector}
                  name="organizationSector"
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleObj}
                  renderValue={() => obj.organizationSector.description}
                  fullWidth
                >
                  {organizationSector.map((e, i) => (
                    <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                      {e.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box id="country">
              <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
                País <span style={{ color: "#FF8200" }}>*</span>
              </Typography>
              <FormControl sx={{ minWidth: 140 }}>
                <Select
                  size="small"
                  sx={{ height: 27, fontSize: 12 }}
                  value={obj.orgCountry}
                  name="orgCountry"
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleObj}
                  renderValue={() => obj.orgCountry.name}
                >
                  {countries.map((e, i) => (
                    <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <DocumentoForm handleDoc={handleObj} obj={obj} />
      </Box>
    )
  );
};

export default DatosOrganizacion;
