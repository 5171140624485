import axios from "axios";
import { proveedores } from "./urls";
import {
  postProveedorDTO,
  postProveedorPersonDTO,
  postProveedorOrgDTO,
} from "./dto/proveedorDTO";

export const postProveedor = async (prov) => {
  const body = postProveedorDTO(prov);
  try {
    let res = await axios.post(`${proveedores}/postSupplier`, body);
    return res.status;
  } catch (e) {
    console.log(e);
  }
};

export const postProveedorPerson = async (prov) => {
  const body = postProveedorPersonDTO(prov);
  try {
    let res = await axios.post(`${proveedores}/postSupplierPerson`, body);
    return res.status;
  } catch (e) {
    console.log(e);
  }
};
export const postProveedorOrg = async (prov) => {
  const body = postProveedorOrgDTO(prov);
  try {
    let res = await axios.post(`${proveedores}/postSupplierOrganization`, body);
    return res.status;
  } catch (e) {
    console.log(e);
  }
};

export const disableProveedor = async (id) => {
  try {
    const res = await axios.put(`${proveedores}/disableSupplier?id=${id}`);
    return res.status;
  } catch (e) {
    console.log(e);
  }
};
