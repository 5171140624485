import { useState } from "react";
import { Link } from "react-router-dom";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user } = useSelector((s) => s);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: 100 / 100,
      }}
    >
      <Button
        color="neutral"
        size="large"
        fontSize={35}
        onMouseEnter={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <AccountCircleIcon sx={{ fontSize: "35px", padding: 1 }} />
        {user}
      </Button>

      <Menu
        id="menu-wrapper"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          marginTop: 2,
          marginLeft: 1,
          justifySelf: "center",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        MenuListProps={{
          sx: {
            background: "#7C878E",
            color: "white",
            width: 180,
          },
        }}
      >
        <Link to="/" style={{ color: "white" }}>
          <MenuItem onClick={() => handleLogout("redirect")}>
            <ListItemIcon>
              <LogoutRoundedIcon color="white" />
            </ListItemIcon>
            <Typography mr={1}>Salir</Typography>
          </MenuItem>
        </Link>
      </Menu>
    </Box>
  );
};

export default UserMenu;
