import { Paper, Box, Typography } from "@mui/material";
import {
  DataGrid,
  useGridApiEventHandler,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  esES,
  getGridStringOperators,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { Link, useLocation } from "react-router-dom";
import { useState, useCallback } from "react";
import Alert from "../Dialog/Alert";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const TablePagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const handleRowClick = (params) => {};
  useGridApiEventHandler(apiRef, "rowClick", handleRowClick);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: 1,
      }}
    >
      <Typography sx={{ color: "#707070", fontSize: 14, padding: 2 }}>
        Total: <span fontWeight={1000}>{page}</span>
      </Typography>
    </Box>
  );
};

const EntityTable = ({ columns, rows, excelData, fileName }) => {
  const columnsDisplay = columns.map((e) => {
    return {
      ...e,
      filterOperators: getGridStringOperators().filter(
        (x) => x.value == "contains" || x.value == "startsWith"
      ),
    };
  });

  const [show, setShow] = useState(false);
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const { pathname } = useLocation();

  const CustomToolbar = useCallback(() => {
    const fileType =
      "aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToExcel = async () => {
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
      <>
        <div
          style={{ bottom: 0, right: 0, margin: 0, padding: 0 }}
          ref={setFilterButtonEl}
        ></div>
        <Box
          sx={{
            background: "#9EAAB866",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            //border: "1px solid #707070",
          }}
        >
          <GridToolbarContainer>
            <GridToolbarFilterButton
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#007749",
                fontSize: 14,
                paddingY: 1,
                paddingRight: 1,
                borderRadius: 0,
                borderLeft: "1px solid #707070",
                textTransform: "uppercase !important",
                ":hover": { fontWeight: 600 },
              }}
            />
            <Link to={`${pathname}/agregar`}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#007749",
                  fontSize: 14,
                  paddingY: 1,
                  paddingRight: 1,

                  borderLeft: "1px solid #707070",
                  ":hover": { fontWeight: 600 },
                }}
              >
                <AddIcon sx={{ paddingX: 0.5 }} /> ALTA
              </Typography>
            </Link>
            <Typography
              onClick={() => exportToExcel()}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#007749",
                fontSize: 14,
                paddingY: 1,
                paddingRight: 1,
                borderLeft: "1px solid #707070",
                ":hover": { fontWeight: 600 },
                cursor: "pointer",
              }}
            >
              <TableChartOutlinedIcon sx={{ paddingX: 1 }} />
              EXPORTAR
            </Typography>
          </GridToolbarContainer>
        </Box>
      </>
    );
  }, [fileName, excelData]);

  return (
    <>
      {rows.length < 1 ? (
        <Alert
          title="No se ha podido cargar la información"
          cancel={false}
          open={show}
          setOpen={setShow}
        />
      ) : null}

      <Paper
        id="entidadesContainer"
        sx={{ height: "auto", backgroundColor: "white", width: 100 / 100 }}
      >
        <Box
          sx={{
            height: 445,
            width: "100%",
            ".MuiDataGrid-root": {
              border: "1px solid #707070",
              ":focus": {
                outline: "none",
              },
              ":focus-within": {
                outline: "none",
              },
            },
            ".MuiDataGrid-columnHeaders": {
              color: "#FFFFFF",
              backgroundColor: "#9EAAB8",
              display: "flex",
              alignItems: "center",
              border: "1px solid #707070",
            },
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: 600,
            },
            ".MuiDataGrid-columnHeaderDraggableContainer": {
              display: "flex",
              justifyContent: "center",
              height: 100 / 100,
              alignItems: "center",
            },
            "& .MuiDataGrid-cell": {
              color: "#707070",
              textAlign: "center",
            },
          }}
        >
          <DataGrid
            rows={rows}
            getRowClassName={(params) =>
              params.row.id % 2 === 0 ? "even" : "odd"
            }
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            columns={columnsDisplay}
            disableSelectionOnClick
            disableColumnSelector
            hideFooter
            fileName={fileName}
            excelData={excelData}
            components={{
              Toolbar: CustomToolbar,
              Footer: TablePagination,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Paper>
    </>
  );
};

export default EntityTable;
