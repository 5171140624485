import { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import BreadcrumBar from "../../components/Breadcrumb/BreadcrumBar";
import { getWidth } from "./../utils";
import EntityTable from "./../../components/EntityTable/EntityTable";
import { getAllServicios } from "../../controllers/servicios";
import { createServColumns, excelServicios, serviceRows } from "./utils";

const Servicios = () => {
  const [loading, setLoading] = useState(true);
  const [servicios, setServicios] = useState([]);
  const rows = serviceRows(servicios);
  const columns = createServColumns();
  const tableW = getWidth(columns) + 5;

  useEffect(() => {
    const getServs = async () => {
      const res = await getAllServicios();
      setServicios(res);
      setLoading(false);
    };
    getServs();
  }, []);

  const excelData = excelServicios(servicios);
  return (
    <Box
      id="servicios-container"
      display={"flex"}
      flexDirection={"column"}
      width={100 / 100}
    >
      <BreadcrumBar breadcrumbs={"Gestión de servicios"} />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 500,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            width={tableW}
            paddingBottom={3}
            height={85 / 100}
            justifySelf="center"
            alignSelf={"center"}
            id="table-container"
          >
            <Box display={"flex"} flexDirection="column" width={100 / 100}>
              <EntityTable
                columns={columns}
                rows={rows}
                fileName="servicios"
                excelData={excelData}
              />
            </Box>
            <Typography
              paddingTop={3}
              alignSelf={"start"}
              fontSize={15}
              color={"#707070"}
            >
              Total: <b>{servicios.length}</b>
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Servicios;
