import axios from "axios";
import { party } from "./urls";
import { getClienteDTO } from "./dto/clientDTOs";
import { getProveedorDTO } from "./dto/proveedorDTO";
import { updateOrgDTO, updatePersonDTO } from "./dto/partyDTO";

export const getAllParty = async (role) => {
  try {
    let res = await axios.get(`${party}/getAllParty?role=${role}`);
    res = res.data;
    res = res.length > 0 ? res : [];
    return res;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getPartyById = async (id, role) => {
  try {
    let res = await axios.get(`${party}/getPartyById?id=${id}&role=${role}`);
    res = res.data;
    if (role === "CLIENTE") {
      res = getClienteDTO(res);
    } else if (role === "PROVEEDOR") {
      res = getProveedorDTO(res);
    }
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getPartyByDoc = async (doc) => {
  try {
    let res = await axios.get(`${party}/GetPartyByDoc?doc=${doc}`);
    res = res.data;
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updatePerson = async (data, id) => {
  try {
    const body = updatePersonDTO(data);
    const res = await axios.put(`${party}/updatePartyPerson?id=${id}`, body);
    return res.status;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrg = async (data, id) => {
  const body = updateOrgDTO(data);
  try {
    const res = await axios.put(`${party}/updatePartyOrg?id=${id}`, body);
    return res.status;
  } catch (error) {
    console.log(error);
  }
};

export const disableRelationship = async (id) => {
  try {
    const res = await axios.delete(`${party}/disable?id=${id}`);
    return res.status;
  } catch (error) {
    console.log(error);
  }
};
