import moment from "moment";
import { metodos } from "./utils";

export const personaDTO = (obj) => {
  const { user } = obj;

  return {
    person: {
      name: obj.personName,
      surname: obj.personSurname,
      gender: obj.gender,
      dateOfBirth: obj.dateOfBirth,
      createdBy: user,
    },
    party: {
      partyType: obj.partyType,
      description: `${obj.personName} ${obj.personSurname}`,
      createdBy: user,
    },
    doc: {
      documentTypeId: obj.documentType.id,
      documentNumber: parseInt(obj.documentNumber),
      createdBy: user,
    },
    contactMethods: [
      {
        value: obj.telefono,
        contactMethodTypeId: metodos.telefono,
        createdBy: user,
      },
      {
        value: obj.email,
        contactMethodTypeId: metodos.email,
        createdBy: user,
      },
      {
        value: "",
        contactMethodTypeId: metodos.direccion,
        createdBy: user,
        address: {
          addressLine1: obj.addressLine1,
          addressLine2: `${obj.addressNumber} ${obj.addressDpto}`,
          city: obj.city,
          state: obj.state,
          countryId: obj.country.id,
          postalCode: obj.postalCode,
          createdBy: user,
        },
      },
    ],
    partyToId: obj.partyTo.id,
    taxConditionTypeId: obj.taxConditionType.id,
    fromDate: obj.fromDate,
    createdBy: user,
    comment: obj.comment,
  };
};

export const orgDTO = (obj) => {
  const { user } = obj;
  return {
    organization: {
      registeredName: obj.orgRegisteredName,
      name: obj.orgName,
      countryId: obj.orgCountry.id,
      organizationSectorId: obj.organizationSector.id,
      organizationTypeId: obj.organizationType.id,
    },
    party: {
      partyType: obj.partyType,
      description: obj.orgName,
      createdBy: user,
    },
    doc: {
      documentTypeId: obj.documentType.id,
      documentNumber: parseInt(obj.documentNumber),
      createdBy: user,
    },
    contactMethods: [
      {
        value: obj.telefono,
        contactMethodTypeId: metodos.telefono,
        createdBy: user,
      },
      {
        value: obj.email,
        contactMethodTypeId: metodos.email,
        createdBy: user,
      },
      {
        value: "",
        contactMethodTypeId: metodos.direccion,
        createdBy: user,
        address: {
          addressLine1: obj.addressLine1,
          addressLine2: `${obj.addressNumber},${obj.addressDpto}`,
          city: obj.city,
          state: obj.state,
          countryId: obj.country.id,
          postalCode: obj.postalCode,
          createdBy: user,
        },
      },
    ],
    partyToId: obj.partyTo.id,
    taxConditionTypeId: obj.taxConditionType.id,
    fromDate: obj.fromDate,
    createdBy: user,
    comment: obj.comment,
  };
};

export const clientDTO = (obj) => {
  const { partyTo, fromDate, user, taxConditionType } = obj;

  return {
    partyFromId: obj.id,
    contactMethods: [
      {
        value: obj.telefono,
        contactMethodTypeId: metodos.telefono,
        createdBy: user,
      },
      {
        value: obj.email,
        contactMethodTypeId: metodos.email,
        createdBy: user,
      },
      {
        value: "",
        contactMethodTypeId: metodos.direccion,
        createdBy: user,
        address: {
          addressLine1: obj.addressLine1,
          addressLine2: `${obj.addressNumber},${obj.addressDpto}`,
          city: obj.city,
          state: obj.state,
          countryId: obj.country.id,
          postalCode: obj.postalCode,
          createdBy: user,
        },
      },
    ],
    taxConditionTypeId: taxConditionType.id,
    partyToId: partyTo.id,
    fromDate: fromDate,
    createdBy: user,
  };
};

export const getClienteDTO = (obj) => {
  const {
    doc,
    person,
    organization,
    description,
    contactMethods,
    partyType,
    partyRelationships,
    comment,
  } = obj;
  const contacts = {
    telefono: contactMethods.filter(
      (x) => x.contactMethodType.id === metodos.telefono
    ),
    email: contactMethods.filter(
      (x) => x.contactMethodType.id === metodos.email
    ),
    address: contactMethods.filter(
      (x) => x.contactMethodType.id === metodos.direccion
    ),
  };
  let num = contacts.address[0] ? contacts.address[0].address.addressLine2 : "";
  num = num.split(",");
  return {
    id: obj.id,
    partyType: partyType,
    partyDescription: description,
    orgRegisteredName: organization ? organization.registeredName : "",
    orgName: organization ? organization.name : "",
    organizationSector: organization
      ? organization.organizationSector
      : { id: "", name: "" },
    organizationType: organization
      ? organization.organizationType
      : { id: "", name: "" },
    orgCountry: organization ? organization.country : { id: "", name: "" },
    documentType: doc.documentType,
    documentNumber: doc.documentNumber,
    personName: person ? person.name : "",
    personSurname: person ? person.surname : "",
    gender: person ? person.gender : "",
    dateOfBirth: person ? person.dateOfBirth : "",
    partyTo: { id: "", name: "" },
    fromDate: moment(),
    partyFrom: { id: "", name: "" },
    obs: "",
    email: contacts.email[0] ? contacts.email[0].value : "",
    emailId: contacts.email[0] ? contacts.email[0].id : 0,
    telefono: contacts.telefono[0] ? contacts.telefono[0].value : "",
    telefonoId: contacts.telefono[0] ? contacts.telefono[0].id : 0,
    addressContactId: contacts.address[0] ? contacts.address[0].id : "",
    addressId: contacts.address[0] ? contacts.address[0].address.id : "",
    addressLine1: contacts.address[0]
      ? contacts.address[0].address.addressLine1
      : "",
    addressNumber: num[0] ? num[0] : "",
    addressDpto: num[1] ? num[1] : "",
    city: contacts.address[0] ? contacts.address[0].address.city : "",
    postalCode: contacts.address[0]
      ? contacts.address[0].address.postalCode
      : "",
    state: contacts.address[0] ? contacts.address[0].address.state : "",
    country: contacts.address[0] ? contacts.address[0].address.country : "",
    partyRelationships: partyRelationships,
    taxConditionType: { id: "", description: "" },
    comment: comment,
  };
};
