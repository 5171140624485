import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  CircularProgress,
  Switch,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { getPartyById } from "../../controllers/party";
import Warning from "../Dialog/Warning";
import Success from "../Dialog/Success";
import ConfirmModificar from "../Dialog/ConfirmModificar";

const Relationships = ({ relationships, open, setOpen, disabled, role }) => {
  const [id, setId] = useState(0);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const close = (state) => {
    setSuccess(state);
    setShow(state);
    setOpen(state);
  };
  const terminate = async () => {
    const res = await disabled(id);
    if (res === 200) {
      setSuccess(true);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const getRels = async () => {
      const res = await getPartyById(relationships, role);
      setData(res);
      setLoading(false);
    };
    getRels();
  }, [open, relationships, role]);

  const onTerminate = (id) => {
    setId(id);
    setShow(true);
  };
  const onClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClick}
        sx={{
          padding: 2.5,
          ".MuiPaper-rounded": { borderRadius: 5, padding: 1 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          color="primary"
          sx={{ fontWeight: 800 }}
        >
          Relaciones
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                width: 400,
                diplay: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  color: "#7C878E",
                  fontWeight: 800,
                  paddingBottom: 2,
                  paddingX: 1.4,
                }}
              >
                {data && data.description}
              </Typography>
              {data &&
                data.partyRelationships.map((p, i) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        // gap: 1,
                        // gridTemplateColumns: "repeat(3, 1fr)",
                        alignItems: "center",
                        border: "1px solid #707070",
                        borderRadius: 2.5,
                        paddingLeft: 2.5,
                        margin: 1,
                      }}
                      key={i}
                    >
                      <Typography sx={{ color: "#7C878E", fontSize: "13px" }}>
                        {p.description}
                      </Typography>
                      {/* {e.status === "ACTIVO" ? (
                        <Button size="small" onClick={() => onTerminate(e.id)}>
                          Finalizar
                        </Button>
                      ) : (
                        <Button size="small" disableFocusRipple={true}>
                          Inactivo
                        </Button>
                      )} */}
                      <Switch
                        checked={p.status === "ACTIVO"}
                        disabled={p.status !== "ACTIVO"}
                        onChange={() => onTerminate(p.id)}
                      />
                    </Box>
                  );
                })}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ alignSelf: "center", paddingBottom: 3 }}>
          <Button variant="contained" size="small" onClick={onClick}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModificar
        confirm={terminate}
        show={show}
        close={setShow}
        accion={`finalizar la relación`}
        item={role}
      />
      <Success
        show={success}
        close={close}
        accion={"ha finalizado el vínculo"}
        item={role}
      />
      <Warning
        show={error}
        close={setError}
        accion={"finalizar la relación"}
        item={role}
      />
    </>
  );
};

export default Relationships;
