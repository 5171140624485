import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#007749",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: 400,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: "'Roboto', sans-serif",
    fontSize: 13,
    "&:hover": {
      color: "#007749",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#007749",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#007749",
    },
  })
);

export default function TabComponent({ tab, handleTab, tabs }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs
          value={tab}
          onChange={handleTab}
          sx={{ fontFamily: "'Roboto', sans-serif" }}
        >
          {tabs.map((e, i) => {
            return <StyledTab key={i} label={e.title} disabled={e.disabled} />;
          })}
        </StyledTabs>
      </Box>
      {tabs.map((e, i) => {
        return (
          <TabPanel key={i} value={tab} index={i}>
            {e.component}
          </TabPanel>
        );
      })}
    </Box>
  );
}
