import { is } from "@babel/types";

export const enableSend = (form, role) => {
  const {
    partyType,
    personName,
    personSurname,
    gender,
    dateOfBirth,
    orgRegisteredName,
    orgName,
    organizationSector,
    organizationType,
    orgCountry,
    documentType,
    documentNumber,
    partyTo,
    telefono,
    addressLine1,
    addressNumber,
    email,
    city,
    postalCode,
    state,
    country,
    taxConditionType,
    businessActivityType,
    categoryType,
    partyRelationships,
  } = form;
  let required = [
    documentNumber,
    documentType.id,
    telefono,
    email,
    addressLine1,
    addressNumber,
    city,
    postalCode,
    state,
    country.id,
  ];
  if (partyType === "PERSONA") {
    required = [...required, personName, personSurname, gender, dateOfBirth];
  } else if (partyType === "ORGANIZACION") {
    required = [
      ...required,
      orgRegisteredName,
      orgName,
      organizationSector,
      organizationType,
      orgCountry,
    ];
  }
  if (form.id === "" || partyRelationships?.length < 1) {
    required = [...required, partyTo.id, taxConditionType.id];
    if (role === "proveedor") {
      required = [...required, businessActivityType.id, categoryType.id];
    }
  }
  return isEmpty(required);
};

export const isEmpty = (values) => {
  if (values.includes("")) return true;
  return false;
};

export const serviceValidator = (state) => {
  const { name, description, serviceType } = state;
  let required = [name, description, serviceType.id];
  return isEmpty(required);
};
