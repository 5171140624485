import { Link, useLocation } from "react-router-dom";
import { Box, ThemeProvider, List, ListItem } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { menus } from "../../utils/utils";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { openSidebar } from "./../../redux/actions";
import { location } from "./utils";

const Sidebar = ({ drawerWidth }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { open } = useSelector((s) => s);
  const theme = useTheme();
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    backgroundColor: "#007749",
    flexShrink: 0,
    position: "absolute",
    whiteSpace: "nowrap",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));
  return (
    <ThemeProvider theme={theme}>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{ sx: { backgroundColor: "#007749" } }}
        onMouseEnter={() => dispatch(openSidebar())}
        onMouseLeave={() => dispatch(openSidebar())}
      >
        <Box
          sx={{
            height: "4.8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 100 / 100,
            cursor: "pointer",
          }}
        >
          {
            !open ? <MenuRoundedIcon color="white" /> : null //<img src={logo} alt="logo" height={40} />
          }
        </Box>
        <List>
          {menus.map((e, i) => (
            <Link key={i} to={e.path}>
              <ListItem
                disablePadding
                sx={{
                  display: "block",
                  borderLeft: location(e.path, pathname) && "2px solid white",
                  background:
                    location(e.path, pathname) && "rgba(250,250,250, 0.3)",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {e.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={e.title}
                    sx={{ opacity: open ? 1 : 0, color: "#ffffff" }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
