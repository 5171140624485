import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  Box,
  //   DialogContentText,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
const Confirm = ({ show, accion, item, confirm, close }) => {
  const onClick = () => {
    close(false);
  };
  return (
    <Dialog
      open={show}
      onClose={onClick}
      PaperProps={{ sx: { padding: 3, borderRadius: 3, width: 350 } }}
    >
      <Box
        sx={{
          background: "#E41E2D",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 150,
          width: 90,
          height: 90,
          alignSelf: "center",
        }}
      >
        <DeleteOutlinedIcon
          sx={{
            fontSize: 70,
            color: "#FFFFFF",
            alignSelf: "center",
            padding: 1,
          }}
        />
      </Box>
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontWeight: 800,
          color: "#E41E2D",
          textAlign: "center",
          paddingTop: 4,
        }}
      >
        ¿Desea {accion} {item}?
      </DialogTitle>{" "}
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText> */}
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button color="black" onClick={onClick} variant={"outlined"}>
          Cancelar
        </Button>
        <Button onClick={confirm} variant={"contained"} color="warning">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
