import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const createRows = (obj) => {
  if (obj.length > 0) {
    const rows = obj.map((p) => {
      return {
        id: p.id,
        name: p.person && p.person.name,
        description: p.organization && p.description,
        partyType: p.partyType === "PERSONA" ? "Física" : "Jurídica",
        documentType: p.identificationDocument?.documentType,
        documentNumber: p.identificationDocument?.documentNumber,
        align: "center",
      };
    });
    return rows;
  } else return obj;
};
export const getWidth = (columns) => {
  let array = columns.map((e) => e.width);
  return array.reduce((a, b) => a + b, 2) + 12;
};

export const createColumns = (setRelationships, setOpen, role) => {
  return [
    {
      field: "name",
      headerName: "Nombre",
      width: 300,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography sx={{ fontSize: 13, paddingLeft: 1 }}>
          {params.row.name || params.row.description}
        </Typography>
      ),
    },
    {
      field: "partyType",
      headerName: "Persona",
      width: 180,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 13, paddingLeft: 1 }}>
            {params.row.partyType}
          </Typography>
        );
      },
    },
    {
      field: "documentType",
      headerName: "Tipo Doc.",
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 13, paddingLeft: 1 }}>
            {params.row.documentType}
          </Typography>
        );
      },
    },
    {
      field: "documentNumber",
      headerName: "Documento",
      width: 120,
      type: "string",
      valueGetter: (params) => String(params.row.documentNumber),
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 13, paddingLeft: 1 }}>
            {params.row.documentNumber.toString()}
          </Typography>
        );
      },
    },
    {
      field: "partyRelationships",
      headerName: "Relaciones",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setRelationships(params.row.id);
            setOpen(true);
          }}
          startIcon={<RemoveRedEyeOutlinedIcon />}
          sx={{
            fontSize: 12,
            ":hover": {
              color: "#FFFFFF",
              backgroundColor: "#007749",
            },
          }}
        >
          Ver
        </Button>
      ),
    },
    {
      field: "Editar",
      headerName: "Acción",
      align: "center",
      headerAlign: "center",
      width: 120,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Link to={`/${role}/editar/${params.row.id}`}>
          <Button
            size="small"
            variant="outlined"
            startIcon={<EditOutlinedIcon />}
            sx={{
              fontSize: 12,
              ":hover": {
                color: "#FFFFFF",
                backgroundColor: "#007749",
              },
            }}
          >
            Editar
          </Button>
        </Link>
      ),
    },
  ];
};

export const data = (rows, setRelationships, setOpen) =>
  rows.map((e) => {
    return {
      ...e,
      relationships: (
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setRelationships(rows.id);
            setOpen(true);
          }}
          startIcon={<RemoveRedEyeOutlinedIcon />}
          sx={{ fontSize: 12 }}
        >
          Ver
        </Button>
      ),
    };
  });

export const dataExcel = (obj) => {
  const data = obj.map((e) => {
    return {
      "Tipo de persona": e.partyType,
      Nombre: e.description,
      Documento: e.identificationDocument.fullDocument,
      Teléfono: e.contactMethods[0].contact,
      Correo: e.contactMethods[1]?.contact,
    };
  });
  return data;
};
