import { useState, useEffect } from "react";
import "@fontsource/open-sans";
import Navbar from "./components/Navbar/Navbar";
import Box from "@mui/material/Box";
import Sidebar from "./components/Sidebar/Sidebar";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home.jsx";
import Clientes from "./pages/Clientes/Clientes";
import Login from "./components/Login/Login";
import { theme } from "./utils/theme";
import LandingPopUp from "./components/Dialog/LandingPopUp";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material";
import CrearCliente from "./pages/Clientes/CrearCliente";
import EditarCliente from "./pages/Clientes/EditarCliente";
import { setUser } from "./redux/actions";
import { useDispatch } from "react-redux";
import Servicios from "./pages/Servicios/Servicios";
import AgregarServicio from "./pages/Servicios/AgregarServicio";
import { setDatosMaestros } from "./redux/actions";
import EditarServicio from "./pages/Servicios/EditarServicio";
import CrearProveedor from "./pages/Proveedores/CrearProveedor";
import EditarProveedor from "./pages/Proveedores/EditarProveedor";
import Proveedores from "./pages/Proveedores/Proovedores";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const [landing, setLanding] = useState(true);
  const drawerWidth = 220;
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;

  useEffect(() => {
    setDatosMaestros(dispatch);
  }, [dispatch]);
  useEffect(() => {
    dispatch(setUser(name));
  });

  return (
    <Box id="AppContainer" sx={{ height: "100%", display: "flex" }}>
      {!isAuthenticated ? (
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      ) : (
        <>
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                height: "100vh",
                width: 100 / 100,
                display: "flex",
                flexDirection: "column",
                background: "#F3F6F9",
              }}
            >
              <Navbar drawerWidth={drawerWidth} />
              <LandingPopUp
                open={isAuthenticated ? false : landing}
                setOpen={setLanding}
              />
              <Box
                id="pages-container"
                sx={{
                  height: "100%",
                  display: "flex",
                  marginLeft: 8,
                }}
              >
                <Sidebar drawerWidth={drawerWidth} />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/clientes" element={<Clientes />} />
                  <Route path="/clientes/agregar" element={<CrearCliente />} />
                  <Route
                    path="/clientes/editar/:id"
                    element={<EditarCliente />}
                  />
                  <Route path="/servicios" element={<Servicios />} />
                  <Route
                    path="/servicios/agregar"
                    element={<AgregarServicio />}
                  />
                  <Route
                    path="/servicios/editar/:id"
                    element={<EditarServicio />}
                  />
                  <Route path="/proveedores" element={<Proveedores />} />
                  <Route
                    path="/proveedores/agregar"
                    element={<CrearProveedor />}
                  />
                  <Route
                    path="/proveedores/editar/:id"
                    element={<EditarProveedor />}
                  />
                </Routes>
              </Box>
            </Box>
          </ThemeProvider>
        </>
      )}
    </Box>
  );
}

export default App;
