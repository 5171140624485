const initialState = {
  welcome: true,
  persons: [],
  organizations: [],
  clients: [],
  proveedores: [],
  countries: [],
  documentType: [],
  organizationSector: [],
  organizationType: [],
  contactMethodType: [],
  serviceType: [],
  murchison: [],
  taxConditionTypes: [],
  businessActivityTypes: [],
  categoryTypes: [],
  user: "",
  open: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "openSidebar":
      return { ...state, open: !state.open };
    case "user":
      return { ...state, user: payload };
    case "welcome":
      return { ...state, welcome: false };
    case "clients":
      return { ...state, clients: payload };
    case "proveedores":
      return { ...state, proveedores: payload };
    case "maestros":
      return {
        ...state,
        countries: payload.countries,
        documentType: payload.documentType,
        organizationSector: payload.organizationSector,
        organizationType: payload.organizationType,
        contactMethodType: payload.contactMethodType,
        murchison: payload.orgs,
        serviceType: payload.serviceType,
        taxConditionTypes: payload.taxConditionTypes,
        businessActivityTypes: payload.businessActivityTypes,
        categoryTypes: payload.categoryTypes,
      };
    case "person":
      return { ...state, persons: payload };
    case "orgs":
      return { ...state, organizations: payload };
    default:
      return state;
  }
};

export default reducer;
