import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  fontFamily: [
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    "Open Sans",
  ].join(","),
  palette: {
    primary: {
      main: "#007749",
      dark: "#7C878E",
    },
    secondary: {
      main: "#707070",
      dark: "#7C878E",
    },
    black: {
      main: "rgba(0,0,0,0.8)",
    },
    white: {
      main: "#FFFFFF",
    },
    neutral: {
      main: "#7C878E",
      dark: "#7C878E",
    },
    warning: {
      main: "#E41E2D",
    },
  },
  typography: {
    fontFamily: "Open Sans",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          "&:hover": {
            boxShadow: "none !important",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 27,
          fontSize: 13,
        },
      },
    },
  },
});
