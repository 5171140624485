import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import BreadcrumBar from "../../components/Breadcrumb/BreadcrumBar";
import { clienteState, orgOrPerson } from "../../components/Forms/utils";
import DocCheck from "../../components/Forms/DocCheck";
import { useSelector } from "react-redux";
import { getPartyByDoc } from "../../controllers/party";
import Form from "../../components/Forms/Form";
import {
  postClientOrg,
  postClient,
  postClientPerson,
} from "../../controllers/client";

const CrearCliente = () => {
  const [check, setCheck] = useState(false);
  const { user } = useSelector((state) => state);
  const [isNew, setIsNew] = useState(true);
  const [cliente, setCliente] = useState({ ...clienteState, user: user });
  const [idCliente, setIdCliente] = useState(0);

  const checkDoc = async () => {
    const id = await getPartyByDoc(cliente.documentNumber);
    if (id > 0) {
      setIdCliente(id);
      setIsNew(false);
    } else {
      setCheck(true);
    }
  };

  //manejo los datos del cliente
  const handleCliente = (e) => {
    const { name, value } = e.target;
    if (name === "comment")
      setCliente({
        ...cliente,
        comment: { ...cliente.comment, description: value },
      });
    else if (name === "documentNumber") {
      if (value.length < 14) {
        setCliente({ ...cliente, [name]: value });
      }
    }
    setCliente({ ...cliente, [name]: value });
  };
  //  maneja las fechas
  const handleBirthDate = (e) => {
    const date = e.$d;
    setCliente({
      ...cliente,
      dateOfBirth: date,
    });
  };
  const handleFromDate = (e) => {
    const date = e.$d;
    setCliente({ ...cliente, fromDate: date });
  };
  const handlePhone = (e) => {
    setCliente({ ...cliente, telefono: e });
  };

  return (
    <Box
      display="flex"
      alignContent={"center"}
      flexDirection={"column"}
      width={100 / 100}
    >
      <BreadcrumBar breadcrumbs={"Gestión de clientes"} />
      <Box
        height={"auto"}
        width={100 / 100}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 500,
            minHeight: "auto",
            background: "white",
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={1}
          >
            <Typography
              sx={{
                color: "#007749",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "'Roboto', sans-serif",
                paddingX: 2,
              }}
            >
              Crear Cliente
            </Typography>
            <Typography sx={{ fontSize: 12, paddingX: 2 }}>
              Los campos <span style={{ color: "#FF8200" }}>*</span> son
              obligatorios
            </Typography>
          </Box>

          <Divider sx={{ color: "#F3F6F9" }} />
          {!check ? (
            <>
              <DocCheck
                obj={cliente}
                handleObj={handleCliente}
                isNew={isNew}
                setIsNew={setIsNew}
              />
              <Box
                id="btn-container"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "flex-end",
                  justifyContent: "space-around",
                  width: 240,
                  padding: 2,
                }}
              >
                <Link to={"/clientes"}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="black"
                    sx={{ fontSize: 12 }}
                  >
                    Cancelar
                  </Button>
                </Link>
                {!isNew ? (
                  <Link to={`/clientes/editar/${idCliente}`}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="black"
                      sx={{ fontSize: 12 }}
                    >
                      Editar
                    </Button>
                  </Link>
                ) : (
                  <Button
                    size="small"
                    disabled={
                      cliente.documentNumber.length < 8 ||
                      cliente.partyType === ""
                    }
                    variant="contained"
                    sx={{ fontSize: 12 }}
                    onClick={checkDoc}
                  >
                    Siguiente
                  </Button>
                )}
              </Box>
            </>
          ) : (
            <>
              <Box
                id="tipo-container"
                width={180}
                paddingY={2}
                paddingX={3}
                justifyContent={"center"}
              >
                <FormControl fullWidth>
                  <Select
                    autoWidth
                    sx={{ height: 27, fontSize: 13 }}
                    size="small"
                    labelId="partyType"
                    inputProps={{ "aria-label": "Without label" }}
                    name="partyType"
                    value={cliente.partyType}
                    onChange={handleCliente}
                  >
                    {orgOrPerson.map((e, i) => (
                      <MenuItem key={i} value={e.value} sx={{ fontSize: 12 }}>
                        {e.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Divider sx={{ color: "#F3F6F9" }} />
              <Form
                obj={cliente}
                handleObj={handleCliente}
                handleFromDate={handleFromDate}
                handleBirthDate={handleBirthDate}
                handlePhone={handlePhone}
                role={"cliente"}
                postOrg={postClientOrg}
                postPerson={postClientPerson}
                postExistent={postClient}
                linkTo={"clientes"}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CrearCliente;
