import axios from "axios";
import { maestros } from "./urls";

export const getDatosMaestros = async () => {
  try {
    let datos = await axios.get(maestros);
    return datos.data;
  } catch (error) {
    console.log(error);
  }
};
