const clientID = process.env.REACT_APP_ID_CLIENTE_AZURE;
const tentantID = process.env.REACT_APP_ID_TENANT_AZURE;
const redirectUrl = process.env.REACT_APP_REDIRECT_URL;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: clientID,
    authority: `https://login.microsoftonline.com/${tentantID}`, //"https://login.microsoftonline.com/common",
    redirectUri: redirectUrl,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read", "Read.Write"],
};

export const userApi = {
  api: "https://loginmdm-murchison1.msappproxy.net//User.Login",
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
