import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";

export default function Alert({
  title,
  content,
  onAcept,
  cancel,
  open,
  setOpen,
}) {
  const onClick = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={onClick}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancel && onAcept && (
          <>
            <Button onClick={onClick}>Cancelar</Button>
            <Button onClick={onAcept}>Aceptar</Button>
          </>
        )}
        {onAcept && !cancel && <Button onClick={onAcept}>Aceptar</Button>}
        {!cancel && !onAcept && <Button onClick={onClick}>Aceptar</Button>}
      </DialogActions>
    </Dialog>
  );
}
