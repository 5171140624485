import { useMsal } from "@azure/msal-react";
import { userApi } from "../../authConfig";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

export const AzureLogin = () => {
  const { instance, accounts } = useMsal();

  const handleLogin = async () => {
    console.log("login");
    const tokenRequest = {
      scopes: [userApi.api],
      account: accounts[0],
    };
    await instance
      .loginRedirect()
      .then((res) => console.log(res))
      .catch((e) => {
        console.log(e);
      });
    try {
      console.log("silent token");
      instance.acquireTokenSilent(tokenRequest).then((res) => {
        let idToken = res.idToken;
        console.log("idToken:", idToken);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        padding: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        size="medium"
        variant="contained"
        className="ml-auto"
        onClick={() => handleLogin()}
      >
        Ingresar
      </Button>
    </Box>
  );
};
