import React from "react";
import Entities from "../../components/EntityTable/EntityTable";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setClients } from "../../redux/actions";
import { useSelector } from "react-redux";
import { getAllParty } from "../../controllers/party";
import { createRows, createColumns, getWidth, dataExcel } from "./../utils";
import { disableRelationship } from "../../controllers/party";
import Relationships from "../../components/Relationships/Relationships";
import BreadcrumBar from "../../components/Breadcrumb/BreadcrumBar";

const Clientes = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [relationships, setRelationships] = useState();
  const disabled = async (id) => {
    return await disableRelationship(id);
  };

  useEffect(() => {
    const consulta = async () => {
      let res = await getAllParty("CLIENTE");
      setLoading(false);
      dispatch(setClients(res));
    };
    consulta();
  }, [dispatch]);

  const { clients } = useSelector((state) => state);

  const rows = createRows(clients);
  const columns = createColumns(setRelationships, setOpen, "clientes");
  const tableW = getWidth(columns);
  const excelData = dataExcel(clients);
  
  return (
    <Box
      id="clientes-container"
      display={"flex"}
      flexDirection={"column"}
      width={100 / 100}
    >
      <BreadcrumBar breadcrumbs={"Gestión de clientes"} />
      <Box
        paddingBottom={3}
        height={85 / 100}
        width={tableW}
        justifySelf="center"
        alignSelf={"center"}
        id="table-container"
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 500,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box display={"flex"} flexDirection="column" width={100 / 100}>
            <Entities
              columns={columns}
              rows={rows}
              fileName="clientes"
              excelData={excelData}
              visibleFields={[]}
            />
            {open && (
              <Relationships
                open={open}
                setOpen={setOpen}
                relationships={relationships}
                disabled={disabled}
                role="cliente"
              />
            )}
            <Typography
              paddingTop={3}
              alignSelf={"start"}
              fontSize={15}
              color={"#707070"}
            >
              Total: <b>{clients.length}</b>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Clientes;
