import { Box, Typography, Divider } from "@mui/material";
import BreadcrumBar from "../../components/Breadcrumb/BreadcrumBar";
import ServicioForm from "../../components/Forms/ServicioForm";
import { useState } from "react";
import { servicioObj } from "./utils";
const AgregarServicio = () => {
  const [servicio, setServicio] = useState(servicioObj);
  const handleServicio = (e) => {
    const { name, value } = e.target;
    setServicio({ ...servicio, [name]: value });
  };
  return (
    <Box
      display="flex"
      alignContent={"center"}
      flexDirection={"column"}
      width={100 / 100}
    >
      <BreadcrumBar breadcrumbs={"Gestión de servicios"} />
      <Box
        height={"auto"}
        width={100 / 100}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 550,
            minHeight: "auto",
            background: "white",
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={1}
          >
            <Typography
              sx={{
                color: "#007749",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "'Roboto', sans-serif",
                paddingX: 2,
              }}
            >
              Crear Servicio
            </Typography>
            <Typography sx={{ fontSize: 13 }}>
              Los campos <span style={{ color: "#FF8200" }}>*</span> son
              obligatorios
            </Typography>
          </Box>
          <Divider sx={{ color: "#F3F6F9" }} />
          <ServicioForm servicio={servicio} handleChange={handleServicio} />
        </Box>
      </Box>
    </Box>
  );
};

export default AgregarServicio;
