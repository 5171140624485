import { Box, Divider, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import TabComponent from "../Tab/Tab";
import { Link } from "react-router-dom";
import { updatePerson, updateOrg } from "./../../controllers/party";
import { enableSend } from "./validator";
import DatosDeContacto from "./../Tab/Tabs/DatosDeContacto";
import DatosDeRelacion from "./../Tab/Tabs/DatosDeRelacion";
import DatosOrganizacion from "./../Tab/Tabs/DatosOrganizacion";
import DatosPersonales from "./../Tab/Tabs/DatosPersonales";
import Observaciones from "./../Tab/Tabs/Observaciones";
import Warning from "./../Dialog/Warning";
import Success from "./../Dialog/Success";
import ConfirmModificar from "../Dialog/ConfirmModificar";

const Form = ({
  obj,
  id,
  handleObj,
  handleBirthDate,
  handleFromDate,
  handlePhone,
  edit,
  role,
  postOrg,
  postPerson,
  postExistent,
  linkTo,
}) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [cont, setCont] = useState(!edit);

  //popup
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [next, setNext] = useState({
    one: false,
    two: !edit,
    three: !edit,
    four: !edit,
  });

  const send = async () => {
    let res;
    setLoading(true);
    if (obj.partyType === "PERSONA") {
      res = await postPerson(obj);
    } else if (obj.partyType === "ORGANIZACION") {
      res = await postOrg(obj);
    }
    if (res === 200) {
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      setError(true);
    }
  };
  const update = async () => {
    let res;
    setLoading(true);
    if (obj.partyTo.id !== "") {
      res = await postExistent(obj);
      if (res !== 200) {
        setError(true);
        setLoading(false);
        return;
      }
    }
    if (obj.partyRelationships.length > 0) {
      if (obj.partyType === "PERSONA") {
        res = await updatePerson(obj, id);
      } else {
        res = await updateOrg(obj, id);
      }
    }
    setConfirm(false);
    if (res === 200) {
      setSuccess(true);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const disabled = enableSend(obj, role);

  const org = {
    component: (
      <DatosOrganizacion
        handleObj={handleObj}
        obj={obj}
        setCont={setCont}
        next={next}
        setNext={setNext}
      />
    ),
    title: "Datos de la organización",
    disabled: next.one,
  };

  const person = {
    component: (
      <DatosPersonales
        handleObj={handleObj}
        handleDate={handleBirthDate}
        obj={obj}
        setCont={setCont}
        next={next}
        setNext={setNext}
        edit={edit}
      />
    ),
    title: "Datos personales",
    disabled: next.one,
  };
  const tabs = [
    obj.partyType === "PERSONA" ? person : org,

    {
      component: (
        <DatosDeRelacion
          handleFromDate={handleFromDate}
          handleObj={handleObj}
          obj={obj}
          role={role}
          setCont={setCont}
          next={next}
          setNext={setNext}
          edit={edit}
        />
      ),
      title: "Datos de la relación",
      disabled: next.two,
    },
    {
      component: (
        <DatosDeContacto
          handleObj={handleObj}
          obj={obj}
          setCont={setCont}
          handlePhone={handlePhone}
          next={next}
          setNext={setNext}
          edit={edit}
        />
      ),
      title: "Datos de contacto",
      disabled: next.three,
    },
    {
      component: <Observaciones obj={obj} handleObj={handleObj} />,
      title: "Observaciones",
      disabled: next.four,
    },
  ];

  const handleTab = (event, num) => {
    if (num <= tabs.length) {
      setTab(num);
    }
  };

  return loading ? (
    <Box
      width={100 / 100}
      height={300}
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Warning
        show={error}
        close={setError}
        accion={edit ? "actualizar" : "dar de alta"}
        item={`el ${role}`}
      />
      <Success
        show={success}
        close={setSuccess}
        accion={edit ? "actualizó" : "dió de alta"}
        item={`el ${role}`}
      />
      <ConfirmModificar
        show={confirm}
        close={setConfirm}
        accion={"modificar"}
        item={`el ${role}`}
        confirm={update}
      />
      <Box height={85 / 100}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 500,
            minHeight: "auto",
            background: "white",
            borderRadius: 2,
          }}
        >
          <Divider sx={{ color: "#F3F6F9" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingX: 3,
            }}
          >
            <TabComponent tab={tab} handleTab={handleTab} tabs={tabs} />
          </Box>

          <Divider sx={{ color: "#F3F6F9" }} />

          <Box
            id="btn-container"
            sx={{
              display: "flex",
              alignItems: "center",
              alignSelf: "flex-end",
              justifyContent: "space-around",
              width: 280,
              padding: 2,
            }}
          >
            <Link to={`/${linkTo}`}>
              <Button
                size="small"
                variant="outlined"
                color="black"
                sx={{ fontSize: 12 }}
              >
                Cancelar
              </Button>
            </Link>
            {edit ? (
              <Button
                size="small"
                disabled={disabled}
                variant="contained"
                onClick={() => setConfirm(true)}
                sx={{ fontSize: 12 }}
              >
                Actualizar
              </Button>
            ) : (
              <Button
                size="small"
                disabled={disabled}
                variant="contained"
                onClick={() => send()}
                sx={{ fontSize: 12 }}
              >
                Guardar
              </Button>
            )}
            <Button
              size="small"
              color="black"
              //disabled={tab > 2 || cont}
              variant="outlined"
              sx={{ fontSize: 12 }}
              onClick={(e) => handleTab(e, tab + 1)}
            >
              Siguiente
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Form;
