const metodos = { telefono: 1, email: 2, direccion: 3 };

export const updatePersonDTO = (obj) => {
  const { user } = obj;
  return {
    party: {
      updatedBy: user,
      description: obj.partyDescription,
      person: {
        surname: obj.personSurname,
        name: obj.personName,
        gender: obj.gender,
        dateOfBirth: obj.dateOfBirth,
        updatedBy: user,
      },
      doc: {
        documentTypeId: obj.documentType.id,
        documentNumber: parseInt(obj.documentNumber),
        updatedBy: user,
      },
    },
    contactMethods: [
      {
        id: obj.telefonoId,
        value: obj.telefono,
        contactMethodTypeId: metodos.telefono,
        updatedBy: obj.user,
        partyId: obj.id,
      },
      {
        id: obj.emailId,
        value: obj.email,
        contactMethodTypeId: metodos.email,
        partyId: obj.id,
        updatedBy: user,
      },
      {
        id: obj.addressContactId,
        value: "",
        contactMethodTypeId: metodos.direccion,
        partyId: obj.id,
        updatedBy: user,
        address: {
          id: obj.addressId,
          addressLine1: obj.addressLine1,
          addressLine2: `${obj.addressNumber},${obj.addressDpto}`,
          city: obj.city,
          state: obj.state,
          countryId: obj.country.id,
          postalCode: obj.postalCode,
          updatedBy: user,
        },
      },
    ],
    comment: obj.comment,
  };
};

export const updateOrgDTO = (obj) => {
  const { user } = obj;

  return {
    party: {
      updatedBy: user,
      description: obj.orgName,
      organization: {
        name: obj.name,
        registeredName: obj.registeredName,
        countryId: obj.countryId,
        organizationSectorId: obj.organizationSectorId,
        organizationTypeId: obj.organizationTypeId,
        updatedBy: user,
      },
      doc: {
        documentTypeId: obj.documentType.id,
        documentNumber: parseInt(obj.documentNumber),
        updatedBy: user,
      },
    },
    contactMethods: [
      {
        id: obj.telefonoId,
        value: obj.telefono,
        contactMethodTypeId: metodos.telefono,
        updatedBy: obj.user,
      },
      {
        id: obj.emailId,
        value: obj.email,
        contactMethodTypeId: metodos.email,
        updatedBy: user,
      },
      {
        id: obj.addressContactId,
        value: "",
        contactMethodTypeId: metodos.direccion,
        updatedBy: user,
        address: {
          id: obj.addressId,
          addressLine1: obj.addressLine1,
          addressLine2: `${obj.addressNumber},${obj.addressDpto}`,
          city: obj.city,
          state: obj.state,
          countryId: obj.country.id,
          postalCode: obj.postalCode,
          updatedBy: user,
        },
      },
    ],
    comment: obj.comment,
  };
};
