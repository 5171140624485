import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import FlagCircleRoundedIcon from "@mui/icons-material/FlagCircleRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";

export const acciones = [
  { id: 1, name: "Consulta" },
  { id: 1, name: "Alta" },
  { id: 1, name: "Modificación" },
  { id: 1, name: "Baja" },
];

export const menus = [
  {
    title: "Inicio",
    icon: <FlagCircleRoundedIcon color="white" />,
    path: "/",
    id: 1,
  },
  {
    title: "Clientes",
    icon: <VerifiedUserRoundedIcon color="white" />,
    path: "/clientes",
    id: 2,
  },
  {
    title: "Proveedores",
    icon: <PeopleAltRoundedIcon color="white" />,
    path: "/proveedores",
    id: 3,
  },
  {
    title: "Servicios",
    icon: <VerifiedRoundedIcon color="white" />,
    path: "/servicios",
    id: 4,
  },
];
