import {
  Box,
  Typography,
  MenuItem,
  Select,
  OutlinedInput,
  TextField,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useSelector } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import { isEmpty } from "../../Forms/validator";
import { useEffect } from "react";

export default function DatosDeContacto({
  handleObj,
  obj,
  handlePhone,
  setCont,
  setNext,
  next,
  edit,
}) {
  const { countries } = useSelector((s) => s);
  const cont = () =>
    isEmpty([
      obj.email,
      obj.telefono,
      obj.addressLine1,
      obj.city,
      obj.state,
      obj.country.name,
      obj.postalCode,
    ]);
  const valid = cont();
  useEffect(() => {
    setCont(edit);
    setNext({ ...next, four: valid });
  }, [obj]);
  return (
    <Box
      id="datos-contacto-container"
      sx={{
        width: 100 / 100,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box padding={1}>
        <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
          Correo electrónico <span style={{ color: "#FF8200" }}>*</span>
        </Typography>
        <OutlinedInput
          name="email"
          value={obj.email}
          onChange={handleObj}
          autoComplete="false"
          type="email"
          fullWidth
          sx={{ height: 27 }}
          inputProps={{ maxLength: 100 }}
        />
      </Box>
      <Box padding={1}>
        <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
          Teléfono <span style={{ color: "#FF8200" }}>*</span>
        </Typography>
        <MuiPhoneNumber
          defaultCountry={"uy"}
          variant="outlined"
          disableAreaCodes
          onChange={handlePhone}
          sx={{ height: 27 }}
          value={obj.telefono}
          inputProps={{ maxLength: 50 }}
        />
      </Box>
      <Box
        id="direccion-cont"
        padding={1}
        display={"flex"}
        flexDirection="column"
      >
        <Typography sx={{ fontSize: 12 }}>
          Dirección <span style={{ color: "#FF8200" }}>*</span>
        </Typography>
        <Box
          id="address-container"
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateRows: "repeat(2, 1fr)",
            mt: 1,
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            {/* calle */}
            <TextField
              autoComplete="false"
              value={obj.addressLine1}
              name="addressLine1"
              onChange={handleObj}
              size="small"
              label="Calle"
              inputProps={{ maxLength: 150 }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                  marginBottom: 2,
                  top: "-6px",
                  "&.MuiInputLabel-shrink": { top: 0 },
                },
              }}
              sx={{ width: 300, height: 27 }}
            />
            {/* número */}
            <TextField
              name="addressNumber"
              size="small"
              value={obj.addressNumber}
              onChange={handleObj}
              autoComplete="false"
              label="Número"
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                  marginBottom: 2,
                  top: "-6px",
                  "&.MuiInputLabel-shrink": { top: 0 },
                },
              }}
              sx={{ width: 80, height: 27 }}
            />
            {/* departamento */}
            <TextField
              size="small"
              width={10}
              name="addressDpto"
              value={obj.addressDpto}
              onChange={handleObj}
              autoComplete="false"
              label="Departamento"
              inputProps={{ maxLength: 10 }}
              sx={{ width: 117, height: 27 }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                  marginBottom: 2,
                  top: "-6px",
                  "&.MuiInputLabel-shrink": { top: 0 },
                },
              }}
            />
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            {/* ciudad */}
            <TextField
              size="small"
              variant="outlined"
              name="city"
              value={obj.city}
              sx={{ width: 400, height: 27 }}
              onChange={handleObj}
              autoComplete="false"
              label="Localidad"
              inputProps={{ maxLength: 150 }}
              color="primary"
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                  marginBottom: 2,
                  top: "-6px",
                  "&.MuiInputLabel-shrink": { top: 0 },
                },
              }}
            />
            {/* código postal */}
            <TextField
              inputProps={{ maxLength: 20 }}
              size="small"
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                  marginBottom: 2,
                  top: "-6px",
                  "&.MuiInputLabel-shrink": { top: 0 },
                },
              }}
              name="postalCode"
              value={obj.postalCode}
              sx={{ width: 117, height: 27 }}
              onChange={handleObj}
              autoComplete="false"
              label="Código Postal"
            />
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            {/* provincia */}
            <TextField
              inputProps={{ maxLength: 100 }}
              size="small"
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                  marginBottom: 2,
                  top: "-6px",
                  "&.MuiInputLabel-shrink": { top: 0 },
                },
              }}
              name="state"
              value={obj.state}
              sx={{ width: 350, height: 27 }}
              onChange={handleObj}
              autoComplete="false"
              label="Provincia"
            />
            {/* país */}
            <FormControl>
              {obj.country.name !== "" && <InputLabel>País</InputLabel>}
              <Select
                name="country"
                value={obj.country}
                onChange={handleObj}
                sx={{ width: 170, height: 27 }}
                label={obj.country.name !== "" ? "País" : null}
                renderValue={(selected) => {
                  if (selected.name.length === 0) {
                    return (
                      <Typography sx={{ color: "#70707099", fontSize: 13 }}>
                        País
                      </Typography>
                    );
                  }

                  return selected.name;
                }}
              >
                {countries.map((e, i) => (
                  <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
