import { getDatosMaestros } from "./../controllers/maestro";

//serWelcome
export const setWelcome = () => {
  return { type: "welcome" };
};
// setLogin
export const setLogin = (payload) => {
  return { type: "login", payload: payload };
};

//save persons
export const setPersons = (payload) => {
  return { type: "person", payload: payload };
};

//save organizations
export const setOrgs = (payload) => {
  return { type: "orgs", payload: payload };
};

//save clients
export const setClients = (payload) => {
  return { type: "clients", payload: payload };
};

//save proveedores
export const setProveedores = (payload) => {
  return { type: "proveedores", payload: payload };
};

//get datos maestrop
export const setDatosMaestros = async (dispatch) => {
  let payload = await getDatosMaestros();
  if (payload) {
    dispatch({ type: "maestros", payload: payload });
    return true;
  } else return false;
};

// guardo nombre del usuario
export const setUser = (payload) => {
  return { type: "user", payload: payload };
};

//abrir el sidebar
export const openSidebar = () => {
  return { type: "openSidebar" };
};
