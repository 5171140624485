import {
  Box,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { useSelector } from "react-redux";

const DocumentoForm = ({ obj, handleDoc }) => {
  const { documentType } = useSelector((s) => s);
  return (
    <Box id="documento-container" width={100 / 100} pt={2}>
      <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
        Documento <span style={{ color: "#FF8200" }}>*</span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "auto",
          gap: "10px 5px",
          flexDirection: "row",
        }}
      >
        <Box width={100}>
          <Select
            name="documentType"
            value={obj.documentType}
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: 27, width: 100 / 100, fontSize: 13 }}
            onChange={handleDoc}
            renderValue={() => obj.documentType.description}
          >
            {documentType.map((e, i) => (
              <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                {e.description}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <OutlinedInput
            name="documentNumber"
            value={obj.documentNumber}
            placeholder="número"
            type="number"
            sx={{ height: 27, width: 90 / 100, fontSize: 13 }}
            onChange={handleDoc}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentoForm;
