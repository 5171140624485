import React from "react";
import Entities from "../../components/EntityTable/EntityTable";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { disableRelationship } from "../../controllers/party";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProveedores } from "../../redux/actions";
import { useSelector } from "react-redux";
import { getAllParty } from "../../controllers/party";
import { createRows, createColumns, getWidth, dataExcel } from "./../utils";
import BreadcrumBar from "../../components/Breadcrumb/BreadcrumBar";
import Relationships from "./../../components/Relationships/Relationships";

const Proveedores = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [relationships, setRelationships] = useState();
  const disabled = async (id) => {
    return await disableRelationship(id);
  };

  useEffect(() => {
    const consulta = async () => {
      let res = await getAllParty("PROVEEDOR");
      setLoading(false);
      dispatch(setProveedores(res));
    };
    consulta();
  }, [dispatch]);

  const { proveedores } = useSelector((state) => state);

  const rows = createRows(proveedores);
  const columns = createColumns(setRelationships, setOpen, "proveedores");
  const tableW = getWidth(columns);
  const excelData = dataExcel(proveedores);


  return (
    <Box
      id="proveedores-container"
      display={"flex"}
      flexDirection={"column"}
      width={100 / 100}
    >
      <BreadcrumBar breadcrumbs={"Gestión de proveedores"} />
      <Box
        paddingBottom={3}
        height={85 / 100}
        width={tableW}
        justifySelf="center"
        alignSelf={"center"}
        id="table-container"
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 500,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box display={"flex"} flexDirection="column" width={100 / 100}>
            <Entities
              columns={columns}
              rows={rows}
              fileName="proveedores"
              excelData={excelData}
            />
            {open && (
              <Relationships
                open={open}
                setOpen={setOpen}
                relationships={relationships}
                disabled={disabled}
                role="proveedor"
              />
            )}
            <Typography
              paddingTop={3}
              alignSelf={"start"}
              fontSize={15}
              color={"#707070"}
            >
              Total: <b>{proveedores.length}</b>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Proveedores;
