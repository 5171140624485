import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { RenderCellExpand } from "./RenderCellExpand";

export const servicioObj = {
  name: "",
  description: "",
  serviceType: { id: "", description: "" },
  enabled: "",
};

export const serviceRows = (obj) => {
  if (obj.length > 0) {
    const rows = obj.map((p) => {
      return {
        id: p.id,
        name: p.name,
        description: p.description,
        serviceType: p.serviceType.description,
        enabled: p.enabled ? "Habilitado" : "Deshabilitado",
        align: "center",
      };
    });
    return rows;
  } else return obj;
};

export const createServColumns = () => {
  return [
    {
      field: "name",
      headerName: "Nombre",
      width: 200,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 13, paddingLeft: 1 }}>
            {params.row.name}
          </Typography>
        );
      },
    },
    {
      field: "description",
      headerName: "Descripción",
      width: 250,
      renderCell: RenderCellExpand,
    },
    {
      field: "serviceType",
      headerName: "Tipo de Servicio",
      width: 200,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 13, paddingLeft: 1 }}>
            {params.row.serviceType}
          </Typography>
        );
      },
    },
    {
      field: "enabled",
      headerName: "Condición",
      width: 180,
      renderCell: (params) => {
        let enabled = params.row.enabled === "Habilitado";
        return (
          <Typography
            sx={{
              fontSize: 13,
              paddingLeft: 1,
              color: enabled ? "#007749" : "#E41E2D",
            }}
          >
            {params.row.enabled}
          </Typography>
        );
      },
    },
    {
      field: "edit",
      headerName: "Acción",
      align: "center",
      headerAlign: "center",
      width: 120,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Link to={`/servicios/editar/${params.row.id}`}>
          <Button
            size="small"
            variant="outlined"
            startIcon={<EditOutlinedIcon />}
            sx={{
              fontSize: 12,
              ":hover": {
                color: "#FFFFFF",
                backgroundColor: "#007749",
              },
            }}
          >
            Editar
          </Button>
        </Link>
      ),
    },
  ];
};

export const excelServicios = (obj) => {
  const data = obj.map((e) => {
    return {
      Nombre: e.name,
      Descripción: e.description,
      "Tipo de Servicio": e.serviceType.description,
      Estado: e.enabled ? "Habilitado" : "Deshabilitado",
    };
  });
  return data;
};
