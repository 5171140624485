import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import BreadcrumBar from "../../components/Breadcrumb/BreadcrumBar";
import { useSelector } from "react-redux";
import { getPartyById } from "../../controllers/party";
import Form from "../../components/Forms/Form";
import {
  postClientOrg,
  postClient,
  postClientPerson,
} from "../../controllers/client";

const EditarCliente = () => {
  const [loading, setLoading] = useState(true);
  const [cliente, setCliente] = useState({});
  const { id } = useParams();
  const { user } = useSelector((s) => s);

  //manejo los datos del cliente
  const handleCliente = (e) => {
    const { name, value } = e.target;
    if (name === "comment")
      setCliente({
        ...cliente,
        comment: { ...cliente.comment, description: value },
      });
    else if (name === "documentNumber") {
      if (value.length < 14) {
        setCliente({ ...cliente, [name]: value });
      }
    } else setCliente({ ...cliente, [name]: value });
  };
  //  maneja las fechas
  const handleBirthDate = (e) => {
    const date = e.$d;
    setCliente({
      ...cliente,
      dateOfBirth: date,
    });
  };
  const handleFromDate = (e) => {
    const date = e.$d;
    setCliente({ ...cliente, fromDate: date });
  };
  const handlePhone = (e) => {
    setCliente({ ...cliente, telefono: e });
  };

  useEffect(() => {
    const getParty = async () => {
      let res = await getPartyById(id, "CLIENTE");
      setCliente({ ...res, user: user });
      setLoading(false);
    };
    getParty();
  }, [id, user]);

  return (
    <Box
      id="page-container"
      display="flex"
      flexDirection={"column"}
      width={100 / 100}
    >
      <BreadcrumBar breadcrumbs={"Gestión de clientes"} />
      <Box
        paddingBottom={3}
        height={"auto"}
        width={100 / 100}
        display={"flex"}
        justifyContent={"center"}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 500,
            minHeight: "auto",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={1}
          >
            <Typography
              sx={{
                color: "#007749",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Editar Cliente
            </Typography>
            <Typography sx={{ fontSize: 12, paddingX: 2 }}>
              Los campos <span style={{ color: "#FF8200" }}>*</span> son
              obligatorios
            </Typography>
          </Box>

          <Divider sx={{ color: "#F3F6F9" }} />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 300,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Form
              edit
              obj={cliente}
              id={id}
              handleobj={handleCliente}
              handleFromDate={handleFromDate}
              handleBirthDate={handleBirthDate}
              handlePhone={handlePhone}
              role={"cliente"}
              postOrg={postClientOrg}
              postPerson={postClientPerson}
              postExistent={postClient}
              linkTo={"clientes"}
            />
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default EditarCliente;
