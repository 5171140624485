export const getRelacionesActivas = (rels) => {
  if (rels.length < 1) return [];
  let activas = rels.filter((x) => x.status === "ACTIVO");
  return activas.map((e) => e.description);
};

export const filterMurchiOrgs = (orgs, activas) => {
  let murchi = orgs.filter((e) => !activas.includes(e.name));
  if (murchi.length > 0) return murchi;
  return [];
};

export const trueFalse = [true, false];

export const toPascalCase = (string) => {
  const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
  return capitalized;
};
