import Box from "@mui/material/Box";
import BreadcrumBar from "../../components/Breadcrumb/BreadcrumBar";
import CardMenu from "../../components/CardMenu/CardMenu";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";

const Home = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
      }}
    >
      <BreadcrumBar breadcrumbs={"Administración General"} />
      <Box
        display={"flex"}
        // flexDirection="column"
        alignItems="flex-start"
        justifyContent={"space-around"}
        height={100 / 100}
        padding={5}
      >
        {/* <Grid container spacing={4}>
          <Grid item xs={1}> */}
        <CardMenu
          dir={"/clientes"}
          titulo={"Clientes"}
          icono={
            <VerifiedUserRoundedIcon sx={{ fontSize: 85, color: "#9EAAB8" }} />
          }
        />
        <CardMenu
          dir={"/proveedores"}
          titulo={"Proveedores"}
          icono={
            <PeopleAltRoundedIcon sx={{ fontSize: 85, color: "#9EAAB8" }} />
          }
        />
        {/* </Grid>
          <Grid item xs={1}> */}
        <CardMenu
          dir={"/servicios"}
          titulo={"Servicios"}
          icono={
            <VerifiedRoundedIcon sx={{ fontSize: 85, color: "#9EAAB8" }} />
          }
        />
        {/* </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
};

export default Home;
