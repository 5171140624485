import { Box, Typography, OutlinedInput } from "@mui/material";
import React from "react";

export default function Observaciones({ obj, handleObj }) {
  return (
    <Box>
      <Typography sx={{ fontSize: 14, paddingBottom: 0.5 }}>
        Puede agregar una observación aquí
      </Typography>
      <OutlinedInput
        multiline
        name="comment"
        value={obj.comment.description}
        onChange={handleObj}
        size="small"
        inputProps={{ maxLength: 255 }}
        fullWidth
        sx={{ minHeight: 100, textAlign: "start", alignItems: "flex-start" }}
      />
    </Box>
  );
}
