import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
const Success = ({ show, accion, item, close }) => {
  const navigate = useNavigate();
  const onClick = () => {
    close(false);
    if (!accion.includes("final")) navigate(-1);
  };
  const content = "";
  return (
    <Dialog
      open={show}
      PaperProps={{ sx: { padding: 3, borderRadius: 3, width: 350 } }}
    >
      <CheckCircleIcon
        sx={{ fontSize: 90, color: "#007749", alignSelf: "center" }}
      />
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontWeight: 800, color: "#007749", textAlign: "center" }}
      >
        Se {accion}
        <br />
        {item} correctamente
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button onClick={onClick} variant={"contained"}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Success;
