import { Dialog, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LandingPopUp = ({ open, setOpen }) => {
  const onClose = () => setOpen(false);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{ ".MuiPaper-rounded": { borderRadius: 5 } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        color="neutral"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          height: 250,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontWeight: "bolder",
            fontSize: 25,
            padding: 3,
          }}
        >
          Te damos la bienvenida
        </Typography>
        <Button
          variant="contained"
          size="small"
          sx={{ alignSelf: "center" }}
          onClick={onClose}
        >
          Comenzar
        </Button>
      </Box>
    </Dialog>
  );
};

export default LandingPopUp;
