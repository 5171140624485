import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
const CardMenu = ({ titulo, icono, dir }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: 180,
        width: 290,
        background: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#7C878E",
        borderRadius: 5,
        cursor: "pointer",
      }}
    >
      {icono}
      <Typography fontSize={25} fontWeight={1000} onClick={() => navigate(dir)}>
        {titulo}
      </Typography>
      <Box display={"flex"} paddingY={1}>
        <Typography
          sx={{ ":hover": { color: "#007749", fontWeight: 600 } }}
          fontSize={12}
          paddingX={1}
          onClick={() => navigate(dir)}
        >
          Ver
        </Typography>
        /
        <Typography
          sx={{ ":hover": { color: "#007749", fontWeight: 600 } }}
          fontSize={12}
          paddingX={1}
          onClick={() => navigate(`${dir}/agregar`)}
        >
          Crear
        </Typography>
        /
        <Typography
          sx={{ ":hover": { color: "#007749", fontWeight: 600 } }}
          fontSize={12}
          paddingX={1}
          onClick={() => navigate(dir)}
        >
          Modificar
        </Typography>
      </Box>
    </Box>
  );
};

export default CardMenu;
