import axios from "axios";
import { servicios } from "./urls";
import { servicioPostDTO, servicioPutDTO } from "./dto/servicioDTOs";

export const postServicio = async (servicio) => {
  const body = servicioPostDTO(servicio);
  try {
    let res = await axios.post(`${servicios}/new`, body);
    return res.status;
  } catch (error) {
    console.log(error);
  }
};

export const getAllServicios = async () => {
  try {
    let res = await axios.get(`${servicios}/getAll`);
    res = res.data;
    res = res.length > 0 ? res : [];
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getServicioById = async (id) => {
  try {
    let res = await axios.get(`${servicios}/getById?id=${id}`);
    res = res.data;
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const putServicio = async (obj, id) => {
  try {
    const body = servicioPutDTO(obj);
    let res = await axios.put(`${servicios}/update?id=${id}`, body);
    return res.status;
  } catch (error) {
    console.log(error);
  }
};

export const deleteServicio = async (id, user) => {
  try {
    let res = await axios.delete(`${servicios}/delete?id=${id}&user=${user}`);
    return res.status;
  } catch (error) {
    console.log(error);
  }
};
