import {
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { orgOrPerson } from "./../../Forms/utils";

const DatosDeRegistro = ({ obj, handleObj, isNew, setIsNew }) => {
  const changeDoc = (e) => {
    if (e.target.value.length < 14) {
      const doc = obj.documentNumber;
      handleObj(e);
      if (e.target.value.length < doc.length) {
        setIsNew(true);
      }
    }
  };
  return (
    <Box>
      <Box
        id="tipo-container"
        width={180}
        paddingY={3}
        justifyContent={"center"}
      >
        <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
          Tipo<span style={{ color: "#E41E2D" }}>*</span>
        </Typography>
        <FormControl fullWidth>
          <Select
            autoWidth
            sx={{ height: 27, fontSize: 13 }}
            size="small"
            labelId="partyType"
            inputProps={{ "aria-label": "Without label" }}
            name="partyType"
            value={obj.partyType}
            onChange={handleObj}
          >
            {orgOrPerson.map((e, i) => (
              <MenuItem key={i} value={e.value} sx={{ fontSize: 12 }}>
                {e.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
          Documento <span style={{ color: "#E41E2D" }}>*</span>
        </Typography>
        <OutlinedInput
          fullWidth
          sx={{ height: 27, fontSize: 13, color: "#007749" }}
          name="documentNumber"
          value={obj.documentNumber}
          type="number"
          inputProps={{ maxLength: 50 }}
          onChange={changeDoc}
          endAdornment={
            !isNew && (
              <InputAdornment position="end">
                <WarningRoundedIcon sx={{ color: "#E41E2D" }} />
              </InputAdornment>
            )
          }
        />
        {!isNew && (
          <Typography sx={{ fontSize: 11, padding: 1, color: "#E41E2D" }}>
            El dato ingresado ya se encuentra registrado en la base de datos
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DatosDeRegistro;
