import { Box, Button, Divider, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import DatosServicio from "../Tab/Tabs/DatosServicio";
import TabComponent from "./../Tab/Tab";
import Warning from "./../Dialog/Warning";
import Success from "./../Dialog/Success";
import { serviceValidator } from "./validator";
import {
  postServicio,
  putServicio,
  deleteServicio,
} from "./../../controllers/servicios";
import { useSelector } from "react-redux";
import Confirm from "./../Dialog/Confirm";
import ConfirmModificar from "./../Dialog/ConfirmModificar";

const ServicioForm = ({ servicio, edit, handleChange, id }) => {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((s) => s);
  const [delet, setDelet] = useState(false);

  const tabs = [
    {
      component: (
        <DatosServicio servicio={servicio} handleChange={handleChange} />
      ),
      title: "Datos de Registro",
      disabled: false,
    },
  ];
  const handleTab = (event, num) => {
    if (num <= tabs.length) {
      setTab(num);
    }
  };

  const sendServicio = async () => {
    setLoading(true);
    const res = await postServicio({ ...servicio, user: user });
    setConfirm(false);
    if (res === 200) {
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const updateServicio = async () => {
    setLoading(true);
    const res = await putServicio({ ...servicio, user: user }, id);
    if (res === 200) {
      setModificar(false);
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const darDeBaja = async () => {
    setLoading(true);
    setDelet(true);
    const res = await deleteServicio(id, user);
    if (res === 200) {
      setDelet(false);
      setConfirm(false);
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      setError(true);
    }
  };
  const disabled = serviceValidator(servicio);

  //popup
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [modificar, setModificar] = useState(false);

  return loading ? (
    <Box
      width={100 / 100}
      height={300}
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Warning
        show={error}
        close={setError}
        accion={edit ? (delet ? "dar de baja" : "actualizar") : "dar de alta"}
        item={"el servicio"}
      />
      <Success
        show={success}
        close={setSuccess}
        accion={edit ? "actualizó" : "dió de alta"}
        item={"el servicio"}
      />
      <Confirm
        show={confirm}
        close={setConfirm}
        accion={"dar de baja"}
        item={"el servicio"}
        confirm={darDeBaja}
      />
      <ConfirmModificar
        show={modificar}
        close={setModificar}
        accion={"modificar"}
        item={"el servicio"}
        confirm={updateServicio}
      />
      <Box padding={2}>
        <TabComponent tab={tab} handleTab={handleTab} tabs={tabs} next />
      </Box>
      <Divider sx={{ color: "#F3F6F9" }} />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={edit ? "space-between" : "flex-end"}
      >
        {edit && (
          <Button
            size="small"
            variant="outlined"
            color="black"
            sx={{
              fontSize: 12,
              marginX: 2,
              ":hover": {
                background: "#E41E2D",
                color: "#FFFFFF",
                border: "none",
              },
            }}
            onClick={() => setConfirm(true)}
          >
            Dar de baja
          </Button>
        )}

        <Box
          id="btn-container"
          sx={{
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
            justifyContent: "space-around",
            width: 160,
            padding: 2,
          }}
        >
          <Link to={"/servicios"}>
            <Button
              size="small"
              variant="outlined"
              color="black"
              sx={{ fontSize: 12 }}
            >
              Cancelar
            </Button>
          </Link>
          {edit ? (
            <Button
              size="small"
              disabled={disabled}
              variant="contained"
              onClick={() => setModificar(true)}
              sx={{ fontSize: 12 }}
            >
              Modificar
            </Button>
          ) : (
            <Button
              size="small"
              disabled={disabled}
              variant="contained"
              onClick={() => sendServicio()}
              sx={{ fontSize: 12 }}
            >
              Crear
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ServicioForm;
