import logo from "../../utils/logo-color.png";
import UserMenu from "../UserMenu/UserMenu";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AppBar, ThemeProvider, Box, Container, Divider } from "@mui/material";
import { theme } from "../../utils/theme";
import { useSelector } from "react-redux";

const Navbar = () => {
  const { open } = useSelector((s) => s);
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        id="appbar-container"
        position="static"
        color="white"
        sx={{
          height: "4.8rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          boxShadow: "none",
          width: 100 / 100,
        }}
      >
        <Box
          sx={{
            height: 100 / 100,
            width: 70,
          }}
        ></Box>
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "2rem",
            height: "100%",
          }}
          maxWidth={false}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!open && <img src={logo} alt="logo" height={67} />}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{ display: "flex", alignItems: "center", height: 100 / 100 }}
            >
              <HelpOutlineIcon
                color="neutral"
                fontSize="large"
                sx={{ padding: 2 }}
              />
              <Divider orientation="vertical" />
            </Box>
            <UserMenu />
          </Box>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navbar;
