import {
  Dialog,
  DialogTitle,
  // DialogContent,
  // DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
const Warning = ({ show, accion, item, close }) => {
  const onClick = () => {
    close(false);
  };

  return (
    <Dialog
      open={show} //onClose={onClick}
      PaperProps={{ sx: { padding: 3, borderRadius: 3, width: 350 } }}
    >
      <WarningRoundedIcon
        sx={{ fontSize: 90, color: "#FF8200", alignSelf: "center" }}
      />
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontWeight: 800, color: "#007749", textAlign: "center" }}
      >
        No es posible {accion} <br />
        {item}
      </DialogTitle>
      {/* <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent> */}
      <DialogActions
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button onClick={onClick} variant={"contained"}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Warning;
