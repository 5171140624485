import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import { filterMurchiOrgs, getRelacionesActivas, toPascalCase } from "./utils";
import { isEmpty } from "../../Forms/validator";
import "dayjs/locale/es-us";
import { useEffect } from "react";

export default function DatosDeRelacion({
  obj,
  handleObj,
  handleFromDate,
  role,
  setCont,
  next,
  setNext,
  edit,
}) {
  const { murchison, taxConditionTypes, businessActivityTypes, categoryTypes } =
    useSelector((s) => s);
  const relaciones = obj.partyRelationships
    ? getRelacionesActivas(obj.partyRelationships)
    : [];

  const murchiOrgs = filterMurchiOrgs(murchison, relaciones);
  const handleRel = () => {};
  const cont = () =>
    role === "proveedor"
      ? isEmpty([
          obj.partyTo,
          obj.fromDate,
          obj.taxConditionType.description,
          obj.businessActivityType.description,
          obj.categoryType.description,
        ])
      : isEmpty([obj.partyTo, obj.fromDate, obj.taxConditionType.description]);

  const valid = cont();
  useEffect(() => {
    setCont(edit);
    setNext({ ...next, three: !edit });
  }, [obj]);

  return (
    <Box
      id="datos-contacto-container"
      width={100 / 100}
      component="form"
      display="flex"
      flexDirection={"column"}
      justifyContent="space-around"
    >
      {relaciones.length > 0 && (
        <Box width={150} paddingY={3}>
          <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
            {toPascalCase(role)} de
          </Typography>
          <Select
            size="small"
            sx={{ height: 27 }}
            inputProps={{ "aria-label": "Without label" }}
            value={relaciones[0]}
            onChange={handleRel}
            fullWidth
          >
            {relaciones.map((e, i) => (
              <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      {murchiOrgs.length > 0 ? (
        <Box
          id="input-container"
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "repeat(2, 1fr)",
            paddingY: 3,
          }}
        >
          <Box>
            <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
              Organización de la que será {role}
              <span style={{ color: "#FF8200" }}>*</span>
            </Typography>
            <Select
              size="small"
              sx={{ height: 27 }}
              value={obj.partyTo}
              name="partyTo"
              onChange={handleObj}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
            >
              {murchiOrgs.map((e, i) => (
                <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box id="date" sx={{ width: 150 }}>
            <Typography
              id="date-title"
              sx={{ fontSize: 12, paddingBottom: 0.5 }}
            >
              Fecha de Inicio
            </Typography>
            <LocalizationProvider
              id="localization-provider"
              class="localize"
              dateAdapter={AdapterDayjs}
            >
              <DesktopDatePicker
                id="date-picker"
                inputProps={{
                  "aria-label": "Without label",
                  sx: { paddingY: 0.3, paddingX: 0.4 },
                }}
                adapterLocale="es-us"
                inputFormat="DD/MM/YYYY"
                value={obj.fromDate}
                onChange={handleFromDate}
                renderInput={(params) => (
                  <TextField
                    id="date-input"
                    name="fromDate"
                    size="small"
                    sx={{ height: 27 }}
                    key={params + 1}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
              Condición impositiva:
              <span style={{ color: "#FF8200" }}>*</span>
            </Typography>
            <Select
              size="small"
              sx={{ height: 27 }}
              value={obj.taxConditionType}
              name="taxConditionType"
              onChange={handleObj}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
            >
              {taxConditionTypes.map((e, i) => (
                <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                  {e.description}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {role === "proveedor" && (
            <>
              <Box>
                <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
                  Actividad:
                  <span style={{ color: "#FF8200" }}>*</span>
                </Typography>
                <Select
                  size="small"
                  sx={{ height: 27 }}
                  value={obj.businessActivityType}
                  name="businessActivityType"
                  onChange={handleObj}
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                >
                  {businessActivityTypes.map((e, i) => (
                    <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                      {e.description}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Typography sx={{ fontSize: 12, paddingBottom: 0.5 }}>
                  Categoría:
                  <span style={{ color: "#FF8200" }}>*</span>
                </Typography>
                <Select
                  size="small"
                  sx={{ height: 27 }}
                  value={obj.categoryType}
                  name="categoryType"
                  onChange={handleObj}
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                >
                  {categoryTypes.map((e, i) => (
                    <MenuItem key={i} value={e} sx={{ fontSize: 12 }}>
                      {e.description}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Typography sx={{ fontSize: 14, paddingBottom: 0.5 }}>
          La parte ya es {role} de todas las organizaciones del grupo
        </Typography>
      )}
    </Box>
  );
}
