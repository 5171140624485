import { useState } from "react";
import TabComponent from "../Tab/Tab";
import Box from "@mui/material/Box";
import DatosDeRegistro from "../Tab/Tabs/DatosDeRegistro";
import { Divider } from "@mui/material";

const DocCheck = ({ obj, handleObj, isNew, setIsNew }) => {
  const [tab, setTab] = useState(0);

  const handleTab = (event, num) => {
    if (num <= tabs.length) {
      setTab(num);
    }
  };
  const tabs = [
    {
      component: (
        <DatosDeRegistro
          obj={obj}
          handleObj={handleObj}
          isNew={isNew}
          setIsNew={setIsNew}
        />
      ),
      title: "Datos de Registro",
    },
  ];
  return (
    <>
      <Box paddingX={3} paddingY={1}>
        <TabComponent tab={tab} handleTab={handleTab} tabs={tabs} />
      </Box>
      <Divider />
    </>
  );
};

export default DocCheck;
