import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
} from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
const ConfirmModificar = ({ show, accion, item, confirm, close }) => {
  const onClick = () => {
    close(false);
  };
  return (
    <Dialog
      open={show}
      onClose={onClick}
      PaperProps={{ sx: { padding: 3, borderRadius: 3, width: 350 } }}
    >
      <WarningRoundedIcon
        sx={{
          fontSize: 100,
          color: "#FF8200",
          alignSelf: "center",
          padding: 1,
        }}
      />
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontWeight: 800,
          color: "#007749",
          textAlign: "center",
          paddingTop: 4,
        }}
      >
        ¿Desea {accion} {item}?
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText> */}
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button color="black" onClick={onClick} variant={"outlined"}>
          Cancelar
        </Button>
        <Button onClick={confirm} variant={"contained"} color="primary">
          {accion.includes("finalizar") ? "Finalizar" : "Aceptar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModificar;
