import axios from "axios";
import { clientes } from "./urls";
import {
  orgDTO,
  personaDTO,
  clientDTO,
} from "./dto/clientDTOs";


export const postClientOrg = async (client) => {
  const body = orgDTO(client);
  try {
    let res = await axios.post(`${clientes}/postClientOrganization`, body);
    return res.status;
  } catch (e) {
    console.log(e);
  }
};
export const postClientPerson = async (client) => {
  const body = personaDTO(client);
  try {
    let res = await axios.post(`${clientes}/postClientPerson`, body);
    return res.status;
  } catch (e) {
    console.log(e);
  }
};

export const postClient = async (client) => {
  const body = clientDTO(client);
  try {
    let res = await axios.post(`${clientes}/postClient`, body);
    return res.status;
  } catch (e) {
    console.log(e);
  }
};


export const disableClient = async (id) => {
  try {
    const res = await axios.put(`${clientes}/disableClient?id=${id}`);
    return res.status;
  } catch (e) {
    console.log(e);
  }
};
