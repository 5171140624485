import React from "react";
import { Breadcrumbs, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function BreadcrumBar({ breadcrumbs }) {
  return (
    <Breadcrumbs
      // mt={1}
      separator="›"
      aria-label="breadcrumb"
      sx={{
        alignSelf: "flex-start",
        paddingX: 4,
        paddingY: 1,
      }}
    >
      <Box sx={{ fontSize: 20, color: "#7C878E" }}>
        <Link style={{ fontWeight: 600, color: "#7C878E" }} to={"/"}>
          MDM
        </Link>
      </Box>

      <Typography sx={{ letterSpacing: 1 }}>{breadcrumbs}</Typography>
    </Breadcrumbs>
  );
}
